import * as React from "react"
import Heading from "../components/Heading"
import Layout from "../components/Layout"
import Paragraph from "../components/Paragraph"

const NotFoundPage = () => {
  return (
    <Layout title="Page Not Found">
      <Heading level="1">Oh no &mdash; we couldn't find that page!</Heading>
      <Paragraph>
        We're very sorry but the link you have doesn't seem to relate to a page we know about.
        <br />
        You can email us at <a href="mailto:wedding@victoriaandluke.com">wedding@victoriaandluke.com</a> if you think something's not right.
      </Paragraph>
    </Layout>
  )
}

export default NotFoundPage
